<template>
  <div class="sidebar-menu fixed">
    <div class="sidebar-menu__top">
      <router-link
        :to="localizedRoute('/i/faq')"
        @click.native="closeMenu"
        class="header-top-faq"
        title="Faq"
      />
      <account-icon
        @click.native="closeMenu"
      />

      <button
        type="button"
        :aria-label="$t('Close')"
        class="btn-close"
        @click="closeMenu"
      />
    </div>

    <div class="sidebar-menu__container" ref="container">
      <ul
        v-if="submenu.depth"
        class="sidebar-menu__list"
      >
        <li>
          <sub-btn
            type="back"
            :name="$t('Back')"
          />
        </li>
      </ul>

      <ul class="sidebar-menu__list" :style="mainListStyles">
        <!-- Home -->
        <li
          v-if="!submenu.depth"
          @click="closeMenu"
        >
          <router-link
            :to="localizedRoute('/')"
            exact
          >
            {{ $t('Home') }}
          </router-link>
        </li>

        <!-- Categories -->
        <li
          :key="category.slug"
          @click="closeMenu"
          v-for="category in visibleCategories"
        >
          <div
            v-if="isCurrentMenuShowed"
            class="subcategory-item"
          >
            <sub-btn
              v-if="category.children_count > 0"
              :id="category.id"
              :name="category.name"
            />
            <router-link
              v-else
              :to="categoryLink(category)"
            >
              {{ category.name }}
            </router-link>
          </div>

          <sub-category
            :category-links="category.children_data"
            :id="category.id"
            :name="category.name"
            :parent-slug="category.slug"
            :parent-path="category.url_path"
          />
        </li>

        <!-- Static -->
        <li
          v-if="!submenu.depth"
          class="bg-dark"
          @click="closeMenu"
        >
          <router-link
            :to="localizedRoute('/')"
          >
            Angebot anfordern
          </router-link>
        </li>
        <li
          v-if="!submenu.depth"
          class="bg-dark"
          @click="closeMenu"
        >
          <router-link
            :to="localizedRoute('/contacts')"
          >
            <img
              src="/assets/icon/icon-contact.svg" alt="Schreiben Sie uns"
            >
            Schreiben Sie uns
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import i18n from '@vue-storefront/i18n'
import SidebarMenu from '@vue-storefront/core/compatibility/components/blocks/SidebarMenu/SidebarMenu'
import SubBtn from 'theme/components/core/blocks/SidebarMenu/SubBtn'
import SubCategory from 'theme/components/core/blocks/SidebarMenu/SubCategory'
import { formatCategoryLink } from '@vue-storefront/core/modules/url/helpers'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import AccountIcon from 'theme/components/core/blocks/Header/AccountIcon'
import { isServer } from '@vue-storefront/core/helpers'
import { loadMenuCategories } from 'theme/helpers/menu'

export default {
  components: {
    SubCategory,
    SubBtn,
    AccountIcon
  },
  mixins: [SidebarMenu],
  data () {
    return {
      myAccountLinks: [
        {
          id: 1,
          name: i18n.t('My profile'),
          url: '/my-account'
        },
        {
          id: 2,
          name: i18n.t('My shipping details'),
          url: '/my-account/shipping-details'
        },
        {
          id: 3,
          name: i18n.t('My newsletter'),
          url: '/my-account/newsletter'
        },
        {
          id: 4,
          name: i18n.t('My orders'),
          url: '/my-account/orders'
        },
        {
          id: 5,
          name: i18n.t('My loyalty card'),
          url: '#'
        },
        {
          id: 6,
          name: i18n.t('My product reviews'),
          url: '#'
        }
      ],
      componentLoaded: false
    }
  },
  computed: {
    mainListStyles () {
      return this.submenu.depth ? `transform: translateX(${this.submenu.depth * 100}%)` : false
    },
    ...mapState({
      submenu: state => state.ui.submenu,
      currentUser: state => state.user.current
    }),
    getSubmenu () {
      return this.submenu
    },
    visibleCategories () {
      return this.categories.filter(category => {
        return category.product_count > 0 || category.children_count > 0
      })
    },
    isCurrentMenuShowed () {
      return !this.getSubmenu || !this.getSubmenu.depth
    }
  },
  async mounted () {
    this.$nextTick(() => {
      this.componentLoaded = true;
      disableBodyScroll(this.$refs.container)
    })

    this.$nextTick(async () => {
      loadMenuCategories()
    });
  },
  beforeDestroy () {
    clearAllBodyScrollLocks()
  },
  methods: {
    login () {
      if (!this.currentUser && this.isCurrentMenuShowed) {
        this.$nextTick(() => {
          this.$store.commit('ui/setAuthElem', 'login')
          this.$bus.$emit('modal-show', 'modal-signup')
          this.$router.push({ name: 'my-account' })
        })
      }
    },
    categoryLink (category) {
      return formatCategoryLink(category)
    },
    closeMenu () {
      this.$store.commit('ui/setSidebar', false)
      this.$store.commit('ui/setMicrocart', false)
      this.$store.commit('supplies/setSupplies', false)
    }
  }
}
</script>
